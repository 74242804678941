import React, { useState, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { position, size } from 'polished';
import { Link } from 'gatsby';
import Nav from './parts/navigation/nav';
import Contain from './base/contain';
import logo from '../images/logo.svg';
import breakpoints from '../styles/settings/breakpoints';

const Header = ({ siteTitle }) => {
    const headerRef = createRef();
    const [solidHeader, setSolidHeader] = useState(false);
    const [open, setOpen] = useState(false);
    const throttle = (fn, wait) => {
        var time = Date.now();
        return () => {
            if (time + wait - Date.now() < 0) {
                fn();
                time = Date.now();
            }
        };
    };

    useEffect(() => {
        const header = headerRef.current;
        if (solidHeader) {
            header.style.backgroundColor = 'var(--color-background)';
            header.style.borderBottom = '1px solid rgba(255, 255, 255, 0)';
            header.style.boxShadow = '0 2px 2px 0 rgba(0, 0, 0, .1)';
            header.style.color = 'var(--color-black)';
        } else {
            header.style.backgroundColor = 'rgba(0, 0, 0, 0.1)';
            header.style.borderBottom = '1px solid rgba(255, 255, 255, 0.1)';
            header.style.boxShadow = '0 2px 2px 0 rgba(0, 0, 0, 0)';
            header.style.color = 'var(--color-white)';
        }
    }, [solidHeader]);

    if (typeof window !== 'undefined') {
        window.addEventListener(
            'scroll',
            throttle(() => {
                const scrollPos = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;
                if (scrollPos > 50) {
                    setSolidHeader(true);
                } else {
                    setSolidHeader(false);
                }
            }, 25)
        );
    }

    return (
        <SHeader ref={headerRef}>
            <Contain>
                <SContainer>
                    <SLogo>
                        <Link to="/">
                            <img alt="Logo Kinderdagverblijf Madelief" src={logo} />
                        </Link>
                    </SLogo>

                    <SBurger open={open} onClick={() => setOpen(!open)} name="Hamburger Menu Toggle">
                        <span />
                        <span />
                        <span />
                    </SBurger>
                    <Nav open={open} />
                </SContainer>
            </Contain>
        </SHeader>
    );
};

const SContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const SLogo = styled.div`
    width: 110px;

    img {
        width: 100%;
        vertical-align: bottom;
    }

    ${breakpoints.large} {
        width: 180px;
    }
`;

const SHeader = styled.header`
    ${position('fixed', 0, null, null, 0)}
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.24s ease-in;
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    transition-property: background-color, box-shadow, border;
    z-index: 100;
`;

const SBurger = styled.button`
    ${position('absolute', '50%', '2rem', null, null)}
    ${size('2rem', '2rem')}
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: transparent;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    ${breakpoints.large} {
        display: none;
    }

    &:focus {
        outline: none;
    }

    span {
        ${size('0.25rem', '2rem')}
        background-color: var(--color-secondary);
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ''
};

export default Header;
