/**
 * SEO component that queries for data with
 *  Gatsby's StaticQuery Component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
    const seoDataQuery = graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `;

    return (
        <StaticQuery
            query={`${seoDataQuery}`}
            render={({ site }) => {
                const metaDescription = description || site.siteMetadata.description;
                return (
                    <Helmet
                        htmlAttributes={{
                            lang
                        }}
                        title={title}
                        titleTemplate={`%s | ${site.siteMetadata.title}`}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription
                            },
                            {
                                property: 'og:title',
                                content: `${title} | ${site.siteMetadata.title}`
                            },
                            {
                                property: 'og:description',
                                content: metaDescription
                            },
                            {
                                property: 'og:type',
                                content: 'website'
                            },
                            {
                                name: 'twitter:card',
                                content: 'summary'
                            },
                            {
                                name: 'twitter:creator',
                                content: site.siteMetadata.author
                            },
                            {
                                name: 'twitter:title',
                                content: `${title} | ${site.siteMetadata.title}`
                            },
                            {
                                name: 'twitter:description',
                                content: metaDescription
                            }
                        ].concat(meta)}
                    />
                );
            }}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: ''
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired
};

export default SEO;
