import { createGlobalStyle } from 'styled-components';
import Colors from './settings/colors';
import Page from './settings/page';
import Typography from './settings/typography';
import breakpoints from './settings/breakpoints';

const GlobalStyle = createGlobalStyle`
    :root {
        ${Page}
        ${Colors}
        ${Typography}
        ${breakpoints}
    }

`;

export default GlobalStyle;
