import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { position, fluidRange } from 'polished';
import breakpoints from '../../../styles/settings/breakpoints';
import ChevronDown from '../../../images/icons/chevron-down.svg';

const NavListItem = ({ data }) => {
    const { primary, fields } = data;
    const metaData = primary.link?._meta;
    const linkTags = metaData?.tags;
    const linkTag = linkTags ? linkTags[0] : '';
    const linkUid = metaData ? metaData.uid : '';
    const link = `${linkTag}/${linkUid}`;
    const label = primary.label[0].text;

    const subItems = [];

    if (fields.length > 0) {
        subItems.push(
            <SNavListSubItem className="is-mobile" key={linkUid}>
                <SNavListLink to={link}>{label}</SNavListLink>
            </SNavListSubItem>
        );
    }

    for (let index = 0; index < fields.length; index += 1) {
        const subItem = fields[index];
        const subNavLabel = subItem.sub_nav_label;
        const subItemLabel = subNavLabel ? subNavLabel[0].text : '';
        const subNavLink = subItem.sub_nav_link;
        const subItemMetaData = subNavLink?._meta;
        const subItemLinkTags = subItemMetaData?.tags;
        const subItemLinkTag = subItemLinkTags ? subItemLinkTags[0] : '';
        const subItemLinkUid = subItemMetaData?.uid;
        const subItemLink = subItemLinkTag ? `/${subItemLinkTag}/${subItemLinkUid}` : `/${subItemLinkUid}`;

        if (subItemLink && subItemLabel) {
            subItems.push(
                <SNavListSubItem key={subItemLinkUid}>
                    <SNavListLink to={subItemLink}>{subItemLabel}</SNavListLink>
                </SNavListSubItem>
            );
        }
    }

    const NavSub = () => {
        if (subItems.length > 0) {
            return <SNavListSub>{subItems}</SNavListSub>;
        }
        return false;
    };

    return (
        <SNavListItem>
            <SNavListLink className={`${subItems.length > 0 ? 'has-subs' : ''}`} to={link}>
                <span>
                    {primary.label[0].text} <ChevronDown />
                </span>
            </SNavListLink>
            <NavSub />
        </SNavListItem>
    );
};

const SNavListLink = styled(Link)`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '16px'
        },
        '320px',
        '1000px'
    )}
    display: block;
    margin-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    transition: color 0.24s ease-in;

    svg {
        display: none;
    }

    ${breakpoints.large} {
        &.has-subs {
            span {
                padding-right: 4px;
                position: relative;

                svg {
                    ${position('absolute', '50%', '0', null, null)}
                    display: block;
                    width: 12px;
                    transition: transform 0.24s ease-in;
                    transform: translate(100%, -50%);
                }
            }
        }
    }
`;

const SNavListSub = styled.ul`
    display: none;

    ${breakpoints.large} {
        ${position('absolute', null, null, '0', '50%')}
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
        transform: translate(-50%, 100%);
        min-width: 200px;
        max-height: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        opacity: 0;
        visibility: hidden;
        background-color: var(--color-white);
    }
`;

const SNavListSubItem = styled.li`
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;

    ${SNavListLink} {
        color: var(--color-black);
    }

    &:hover {
        > ${SNavListLink} {
            color: var(--color-secondary);
        }
    }

    ${breakpoints.large} {
        &.is-mobile {
            display: none;
        }
    }
`;

const SNavListItem = styled.li`
    display: block;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
    text-align: center;

    &:last-child {
        margin-left: 40px;
    }

    &:hover {
        > ${SNavListLink} {
            color: var(--color-secondary);

            svg {
                transform: translate(100%, -20%);
            }
        }

        ${SNavListSub} {
            opacity: 1;
            visibility: visible;
        }
    }

    ${breakpoints.large} {
        display: inline-block;
        text-align: left;
    }
`;

export default NavListItem;

NavListItem.propTypes = {
    data: PropTypes.object.isRequired
};
