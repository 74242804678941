import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../styles/settings/breakpoints';

const Contain = props => {
    const { children } = props;

    const containPadding = () => {
        return `
                padding-right: var(--contain-percentage);
                padding-left: var(--contain-percentage);

                ${breakpoints.large} {
                    padding-right: 0;
                    padding-left: 0;
                }
            `;
    };

    const containWidth = () => {
        return `
                margin-right: auto;
                margin-left: auto;

                ${breakpoints.large} {
                    max-width: 960px;
                }

                ${breakpoints.wide} {
                    max-width: 1113px;
                }

                ${breakpoints.huge} {
                    max-width: 1252px;
                }
            `;
    };

    return (
        <StyledContain padding={containPadding()} maxWidth={containWidth()}>
            {children}
        </StyledContain>
    );
};

const StyledContain = styled.div`
    ${props => props.padding};
    ${props => props.maxWidth};
`;

export default Contain;
