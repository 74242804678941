const Page = () => `
    /**
     * Page (content) background color.
     */
    --background-color: var(--color-white);

    /**
     * Max page width.
     */
    --page-max-width: 1440px;

    /**
     * Contain.
     */
    --contain-percentage: 5vw;

`;

export default Page;
