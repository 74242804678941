import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import PlainList from '../../base/plainList';
import Button from '../../base/button';
import NavListItem from './navListItem';
import { position, size } from 'polished';
import breakpoints from '../../../styles/settings/breakpoints';

const Nav = ({ open }) => {
    const navigationQuery = graphql`
        {
            prismic {
                allNavigations {
                    edges {
                        node {
                            navigation {
                                ... on PRISMIC_NavigationNavigationNav_item {
                                    primary {
                                        label
                                        link {
                                            ... on PRISMIC_Archive {
                                                _meta {
                                                    uid
                                                }
                                            }
                                            ... on PRISMIC_Articles {
                                                _meta {
                                                    uid
                                                }
                                            }
                                            ... on PRISMIC_Single {
                                                _meta {
                                                    uid
                                                    tags
                                                }
                                            }
                                            ... on PRISMIC_Team {
                                                _meta {
                                                    uid
                                                }
                                            }
                                        }
                                    }
                                    fields {
                                        sub_nav_link {
                                            ... on PRISMIC_Single {
                                                _meta {
                                                    uid
                                                    tags
                                                }
                                            }
                                            ... on PRISMIC_Team {
                                                _meta {
                                                    uid
                                                }
                                            }
                                        }
                                        sub_nav_label
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const ListItems = ({ items }) => {
        return items.map((item, index) => {
            return <NavListItem data={item} key={index} />;
        });
    };

    return (
        <StaticQuery
            query={`${navigationQuery}`}
            render={data => {
                const prismicData = data.prismic.allNavigations.edges[0].node.navigation;
                return (
                    <SNavWrapper>
                        <SNav open={open}>
                            <SNavList>
                                <ListItems items={prismicData} />
                                <SButton>
                                    <Button to="inschrijven">Inschrijven</Button>
                                </SButton>
                            </SNavList>
                        </SNav>
                    </SNavWrapper>
                );
            }}
        />
    );
};

const SNavWrapper = styled.div``;

const SNav = styled.nav`
    ${position('absolute', 0, null, null, 0)}
    ${size('100vh', '100%')}
    padding-top: 80px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.24s ease-in;
    background-color: var(--color-background);
    color: var(--color-black);

    ${breakpoints.large} {
        ${size('auto', 'auto')}
        position: static;
        padding-top: 0;
        transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(0)')};
        background-color: unset;
        color: unset;
    }
`;

const SNavList = styled(PlainList)``;

const SButton = styled.li`
    display: block;
    margin-top: 20px;
    text-align: center;

    ${breakpoints.large} {
        display: inline-block;
        margin-top: 0;
        margin-left: 20px;
        text-align: left;
    }
`;

export default Nav;

Nav.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool.isRequired
};
