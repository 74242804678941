import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = ({ children, small, to, external, button, type, disabled = false }) => {
    const getButton = () => {
        if (external) {
            return (
                <SAnchor small={small} href={to} disabled={disabled}>
                    {children}
                </SAnchor>
            );
        } else if(button) {
            return (
                <SButton small={small} type={type} disabled={disabled}>
                    {children}
                </SButton>
            );
        } else {
            return (
                <SLink small={small} to={`/${to}`} disabled={disabled}>
                    {children}
                </SLink>
            );
        }
    };
    return <>{getButton()}</>;
};

const SLink = styled(Link)`
    display: inline-block;
    padding: ${props => (props.small ? '10px 14px 12px 14px' : '14px 20px 16px 20px')};
    min-width: ${props => (props.small ? '120px' : '140px')};
    border: 0;
    border-radius: 6px;
    background-color: var(--color-secondary);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    font-size: ${props => (props.small ? '14px' : '15px')};
    letter-spacing: 1.1px;
    font-weight: 700;
    color: var(--color-white);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const SAnchor = styled.a`
    display: inline-block;
    padding: ${props => (props.small ? '10px 14px 12px 14px' : '14px 20px 16px 20px')};
    min-width: ${props => (props.small ? '120px' : '140px')};
    border: 0;
    border-radius: 6px;
    background-color: var(--color-secondary);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    font-size: ${props => (props.small ? '14px' : '15px')};
    letter-spacing: 1.1px;
    font-weight: 700;
    color: var(--color-white);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const SButton = styled.button`
    display: inline-block;
    padding: ${props => (props.small ? '10px 14px 12px 14px' : '14px 20px 16px 20px')};
    min-width: ${props => (props.small ? '120px' : '140px')};
    border: 0;
    border-radius: 6px;
    background-color: var(--color-secondary);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
    font-size: ${props => (props.small ? '14px' : '15px')};
    letter-spacing: 1.1px;
    font-weight: 700;
    color: var(--color-white);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

export default Button;

Button.propTypes = {
    children: PropTypes.node.isRequired,
    small: PropTypes.string,
    to: PropTypes.string,
    external: PropTypes.bool
};
