const Colors = () => `
    --color-white: rgb(255, 255, 255);
    --color-black: rgb(0, 0, 0);
    --color-light-black: rgb(55, 55, 55);

    --color-primary: rgb(92, 160, 142);
    --color-secondary: rgb(240, 210, 23);
    --color-background: rgb(242, 249, 241);
    --color-background-darker: rgb(230, 249, 229);

    --color-error: rgb(222, 105, 105);

    --color-gray-darker: rgb(92, 92, 92);
    --color-gray-dark: rgb(136, 136, 136);
    --color-gray: rgb(198, 198, 198);
    --color-gray-light: rgb(212, 212, 212);
    --color-gray-lighter: rgb(231, 231, 231);
    --color-gray-lightest: rgb(249, 249, 249);
`;

export default Colors;
