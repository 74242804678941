import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { RichText } from 'prismic-reactjs';
import Grid from './grid/grid';
import GridCell from './grid/gridCell';
import Contain from '../components/base/contain';
import PlainList from '../components/base/plainList';
import breakpoints from '../styles/settings/breakpoints';

const footer = () => {
    const footerQuery = graphql`
        {
            prismic {
                allGeneral_infos {
                    edges {
                        node {
                            site_name
                            address
                            zipcode
                            city
                            phone
                            mail
                            opening_hours {
                                day
                                time
                            }
                        }
                    }
                }
                allFooters {
                    edges {
                        node {
                            footer_column_left_title
                            footer_column_left {
                                label
                                url {
                                    ... on PRISMIC_Single {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Team {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Archive {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Articles {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Article {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                }
                            }
                            footer_column_right_title
                            footer_column_right {
                                label
                                url {
                                    ... on PRISMIC_Single {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Team {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Archive {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Articles {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                    ... on PRISMIC_Article {
                                        _meta {
                                            uid
                                            tags
                                        }
                                    }
                                }
                            }
                            general_links {
                                label
                                url {
                                    ... on PRISMIC_Single {
                                        _meta {
                                            tags
                                            uid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const parseOpeninghours = openingHours => {
        return openingHours.map((openingHour, index) => {
            return (
                <PlainList key={index}>
                    <li>{openingHour.day}</li>
                    <li>{openingHour.time}</li>
                </PlainList>
            );
        });
    };

    const parseColumn = column => {
        return column.map((link, index) => {
            const tag = link.url._meta.tags[0]; //TAG MUST BE THE SAME AS ARCHIVE TAG
            const uid = link.url._meta.uid;
            const url = tag === uid || typeof tag === 'undefined' ? `/${uid}` : `/${tag}/${uid}`;

            return (
                <li key={`${index}-${uid}`}>
                    <Link to={url} title={link.label}>
                        {link.label}
                    </Link>
                </li>
            );
        });
    };

    const parseFooterBottom = links => {
        return links.map(link => {
            const tag = link.url._meta.tags[0]; //TAG MUST BE THE SAME AS ARCHIVE TAG
            const uid = link.url._meta.uid;
            const url = tag === uid || typeof tag === 'undefined' ? `/${uid}` : `/${tag}/${uid}`;

            return (
                <span key={uid}>
                    {' '}
                    |{' '}
                    <Link to={url} title={link.label}>
                        {link.label}
                    </Link>
                </span>
            );
        });
    };

    return (
        <StaticQuery
            query={`${footerQuery}`}
            render={data => {
                const generalInfo = data.prismic.allGeneral_infos.edges[0].node;
                const { site_name, address, zipcode, city, phone, mail, opening_hours } = generalInfo;
                const siteName = site_name[0].text;
                const footer = data.prismic.allFooters.edges[0].node;

                return (
                    <SFooter>
                        <Contain>
                            <SHeading>{siteName}</SHeading>
                            <Grid>
                                <GridCell cellSizes={{ small: '100%', medium: '50%', large: '25%' }}>
                                    <SFooterContent>
                                        <SFooterHeading>Contact gegevens</SFooterHeading>
                                        <PlainList>
                                            <li>{address}</li>
                                            <li>
                                                {zipcode} {city}
                                            </li>
                                            <li>
                                                <a href={`tel:${phone}`} title={`Telefoonnummer ${siteName}`}>
                                                    {phone}
                                                </a>
                                            </li>
                                            <li>
                                                <a href={`mailto:${mail}`} title={`Mail ${siteName}`}>
                                                    {mail}
                                                </a>
                                            </li>
                                        </PlainList>
                                    </SFooterContent>
                                </GridCell>
                                <GridCell cellSizes={{ small: '100%', medium: '50%', large: '25%' }}>
                                    <SFooterContent>
                                        <SFooterHeading>Openingstijden</SFooterHeading>
                                        {parseOpeninghours(opening_hours)}
                                    </SFooterContent>
                                </GridCell>
                                <GridCell cellSizes={{ small: '100%', medium: '50%', large: '25%' }}>
                                    <SFooterContent>
                                        <SFooterHeading>{RichText.render(footer.footer_column_left_title)}</SFooterHeading>
                                        <PlainList>{parseColumn(footer.footer_column_left)}</PlainList>
                                    </SFooterContent>
                                </GridCell>

                                <GridCell cellSizes={{ small: '100%', medium: '50%', large: '25%' }}>
                                    <SFooterContent>
                                        <SFooterHeading>{RichText.render(footer.footer_column_right_title)}</SFooterHeading>
                                        <PlainList>{parseColumn(footer.footer_column_right)}</PlainList>
                                    </SFooterContent>
                                </GridCell>
                            </Grid>
                            <SFooterCopyRight>
                                © {new Date().getFullYear()} {siteName}
                                {parseFooterBottom(footer.general_links)}
                            </SFooterCopyRight>
                        </Contain>
                    </SFooter>
                );
            }}
        />
    );
};

const SFooter = styled.footer`
    padding-top: 60px;
    padding-bottom: 20px;
    font-family: var(--primary-font);
    background-color: var(--color-primary);
    color: var(--color-white);
`;

const SHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '18px',
            toSize: '23px'
        },
        '320px',
        '1440px'
    )};
    margin-bottom: 30px;
    font-weight: 700;

    ${breakpoints.large} {
        margin-bottom: 50px;
    }
`;

const SFooterContent = styled.div`
    margin-top: 20px;

    li {
        margin-top: 4px;
    }

    ul + ul {
        margin-top: 10px;
    }

    ${breakpoints.large} {
        margin-top: 0;
    }
`;

const SFooterHeading = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '17px',
            toSize: '19px'
        },
        '320px',
        '1440px'
    )};
    margin-bottom: 6px;
    font-weight: 600;
`;

const SFooterCopyRight = styled.div`
    margin-top: 40px;

    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '14px',
            toSize: '15px'
        },
        '320px',
        '1440px'
    )};
`;

export default footer;
