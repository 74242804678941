import React from 'react';
import styled from 'styled-components';

const plainList = ({ children, type }) => {
    return <SList>{children}</SList>;
};

const SList = styled.ul`
    li {
        padding-left: 0;

        &::before {
            display: none;
        }
    }
`;

export default plainList;
