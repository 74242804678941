/**
 * Layout component that queries for data
 *  Gatsby's StaticQuery Component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import GlobalStyle from '../styles/global';
import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children }) => {
    const siteDataQuery = graphql`
        {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `;

    return (
        <StaticQuery
            query={`${siteDataQuery}`}
            render={({ site }) => {
                return (
                    <SBase>
                        <Header siteTitle={site.siteMetadata.title} />
                        <div>
                            <SMain>{children}</SMain>
                            <Footer />
                        </div>
                        <GlobalStyle />
                    </SBase>
                );
            }}
        />
    );
};

const SBase = styled.div`
    font-family: var(--primary-font);
    background-color: var(--color-background);
`;

const SMain = styled.div``;

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
